import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";

const { SocialIcon } = require('react-social-icons');


const Footer = () => {
    return (
        <MDBFooter color="elegant-color" className="page-footer font-small pt-4 mt-4">
            <MDBContainer fluid className="text-center text-md-left">
                <MDBRow>
                    <MDBCol md="6">
                        <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Offensive Plugins
                        </h5>
                        <p>
                            At Offensive Plugins we focus on delivering high-quality audio plugins to every corner of the music industry - be it students just starting up, experienced professionals or educational institutes.
                        </p>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    <MDBCol md="2">
                        <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Links
                        </h5>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/about">About</a>
                            </li>
                            <li>
                                <a href="/products">Products</a>
                            </li>
                            <li>
                                <a href="/privacy-notice">Privacy Notice</a>
                            </li>
                            <li>
                                <a href="/terms-conditions">Terms & Conditions</a>
                            </li>
                        </ul>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    <MDBCol md="4" lg="3" xl="3" className="mb-4">
                        <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Contact
                        </h5>
                        <Link to="/support"><MDBBtn color="primary">Support</MDBBtn></Link>
                        <a href="mailto:support@offensiveplugins.com">support@offensiveplugins.com</a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <hr />
            <div className="text-center">
                <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                        <SocialIcon url="https://www.facebook.com/offensiveplugins" />
                    </li>
                    <li className="list-inline-item">
                        <SocialIcon url="https://www.linkedin.com/company/offensive-plugins/" />
                    </li>
                    <li className="list-inline-item">
                        <SocialIcon url="https://twitter.com/OffensivePlgns" />
                    </li>
                    <li className="list-inline-item">
                        <SocialIcon url="https://www.instagram.com/offensiveplugins/" />
                    </li>
                </ul>
            </div>
            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                    &copy; {new Date().getFullYear()} Copyright <a href="https://www.offensiveplugins.com"> OFFENSIVE PLUGINS LTD.</a> All rights reserved. Company number 12652767.
                </MDBContainer>
            </div>
        </MDBFooter>
    );
};

export default Footer;