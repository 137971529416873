import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import CookieConsent from "react-cookie-consent";

import { ToastContainer } from 'react-toastify';

//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import 'react-toastify/dist/ReactToastify.css';

import Navigation from './components/Navigation';
import Footer from './components/Footer';

import Home from './containers/Home';
import Support from './containers/Support';
import Products from './containers/Products';
import Product from './components/Product';
import About from './containers/About';
import Privacy from './containers/Privacy';
import Conditions from './containers/Conditions';

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Navigation />
      <div className="app">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route exact path="/products">
            <Products />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/privacy-notice">
            <Privacy />
          </Route>
          <Route path="/terms-conditions">
            <Conditions />
          </Route>
          <Route path="/products/:id" children={<Product />} />
          <Route path="*">
            <div className="container text-center">
              <h1 class="text-danger mt-5">ERROR 404</h1>
              <a href="/">HOME</a>
            </div>
          </Route>
        </Switch>
      </div>
      <Footer />
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
    </Router>
  );
}

export default App;
