import React, { useState } from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBNavLink
} from "mdbreact";
import { useLocation } from 'react-router-dom';

const Navigation = () => {

    const [collapsed, setCollapsed] = useState(false);
    let location = useLocation();

    return (
        <MDBNavbar color="white" light expand="md" sticky="top" scrolling>
            <MDBNavbarBrand href="/" className="nav-brand">
                <img src="/OffensivePlugins.png" width="200" className="d-inline-block align-top" alt="Offensive Plugins Logo" />
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={() => { setCollapsed(!collapsed); }} />
            <MDBCollapse navbar isOpen={collapsed}>
                <MDBNavbarNav right>
                    <MDBNavItem active={'/products' === location.pathname} >
                        <MDBNavLink to="/products">PRODUCTS</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem active={'/about' === location.pathname}>
                        <MDBNavLink to="/about">ABOUT</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem active={'/support' === location.pathname}>
                        <MDBNavLink to="/support">SUPPORT</MDBNavLink>
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBCollapse>
        </MDBNavbar>
    );
}

export default Navigation;
