import React, { useEffect, useState } from 'react';
import { MDBContainer } from 'mdbreact';
import { toast } from 'react-toastify';

import Prismic from 'prismic-javascript';
import { RichText, Date } from 'prismic-reactjs';

import { Client } from "../prismic-configuration";

const Privacy = () => {
    const [policy, setPolicy] = useState(null);

    useEffect(() => {
        Client.query(
            Prismic.Predicates.at('document.type', 'privacy-notice'),
        ).then((res) => {
            setPolicy({ data: res.results[0].data.policy, dateModified: res.results[0].last_publication_date });
        }).catch((err) => {
            toast.error("Failed to load policy, please reload the page!");
        });
    }, []);

    return (
        <MDBContainer className="support-form">
            <div className="text-center">
                <h1>PRIVACY NOTICE</h1>
                {policy && Date(policy.dateModified).toString()}
            </div>
            <hr className="my-5" />
            {policy && RichText.render(policy.data)}
        </MDBContainer>
    );
};

export default Privacy;