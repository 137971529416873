import React from 'react';
import { MDBRow, MDBCard, MDBCol, MDBContainer, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";
import TopPanel from '../components/TopPanel';
import { NewsCardLeft, NewsCardRight } from '../components/NewsCard';
import Team from '../components/Team';

const Home = () => {
    return (
        <div className="Home">
            <TopPanel />
            <MDBContainer>
                <h4 className="grey-text h4-responsive text-center my-5"><em>Innovative audio software for all levels of expertise in the audio industry.</em></h4>
                <section>
                    <h2 className='h1-responsive font-weight-bold my-5'>Our Vision</h2>
                    <MDBRow>
                        <MDBCol md='12' className='mb-4'>
                            <MDBCard
                                className='h-100'
                                style={{
                                    backgroundImage:
                                        'url(https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <div className='text-white text-center d-flex flex-column align-items-center rgba-black-light py-5 px-4 rounded h-100'>
                                    <h6 className='yellow-text'>
                                        <MDBIcon icon='bolt' />
                                    </h6>
                                    <h3 className='py-3 font-weight-bold'>
                                        <strong>Offensive</strong>
                                    </h3>
                                    <p className='pb-3'>
                                        The audio industry is a battleground; let us drive your offensive!
                                    </p>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='6' className='md-0 mb-4'>
                            <MDBCard
                                className='h-100'
                                style={{
                                    backgroundImage:
                                        'url(https://images.unsplash.com/photo-1572220555770-bd2feb5b7a30?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1353&q=80)',
                                    backgroundPosition: 'right center'
                                }}
                            >
                                <div className='text-white text-center d-flex align-items-center rgba-black-light py-5 px-4 rounded h-100'>
                                    <div>
                                        <h6 className='pink-text'>
                                            <MDBIcon icon='code' />
                                            <strong> Tech</strong>
                                        </h6>
                                        <h3 className='py-3 font-weight-bold'>
                                            <strong>Reliable</strong>
                                        </h3>
                                        <p className='pb-3'>
                                            We take on extensive testing to ensure quality, reliability and robustness.
                                        </p>
                                    </div>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='6' className='md-0 mb-4'>
                            <MDBCard
                                className='h-100'
                                style={{
                                    backgroundImage:
                                        'url(https://images.unsplash.com/photo-1581378576936-9b10bb143de8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80)',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <div className='text-white text-center d-flex align-items-center rgba-black-light py-5 px-4 rounded h-100'>
                                    <div>
                                        <h6 className='green-text'>
                                            <MDBIcon icon='eye' />
                                            <strong> Design</strong>
                                        </h6>
                                        <h3 className='py-3 font-weight-bold'>
                                            <strong>Tailored</strong>
                                        </h3>
                                        <p className='pb-3'>
                                            We create ranges of plugins tailored to multiple different groups in audio production.
                                        </p>
                                    </div>
                                </div>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </section>
                <hr className="my-5" />
                <MDBCardBody>
                    <h1 className="h1-responsive font-weight-bold mb-5">
                        News
                    </h1>
                    <NewsCardRight
                        title="BREK TECH"
                        background="https://images.prismic.io/offensive-plugins/5bc3c3c4-d1e5-4964-852c-44bc46185f49_demo_BrekTech.png?auto=compress,format"
                        tag="NEW RELEASE"
                        description="Feed your mix this hearty Full English multi-FX unit."
                        destination="/products/brek-tech"
                        icon="bolt"
                        date="10.07.2020"
                        actionTitle="VIEW PRODUCT"
                    />
                    <NewsCardLeft
                        title="GLASS"
                        background="https://images.prismic.io/offensive-plugins/86433e40-0b95-4ec6-a2ea-325d6da92dc7_demo_Glass.png?auto=compress,format"
                        tag="NEW RELEASE"
                        description="The transparent compressor built for accurate dynamics control."
                        destination="/products/glass"
                        icon="bolt"
                        date="20.06.2020"
                        actionTitle="VIEW PRODUCT"
                    />
                    <NewsCardRight
                        title="THE DESTROYER"
                        background="https://images.prismic.io/offensive-plugins/68359c34-3b02-48a8-a128-7746604af13b_demo_TheDestroyer.png?auto=compress,format"
                        tag="NEW RELEASE"
                        description="Introduce your mix to Satan with this brutal distortion."
                        destination="/products/the-destroyer"
                        icon="bolt"
                        date="20.06.2020"
                        actionTitle="VIEW PRODUCT"
                    />
                </MDBCardBody>
            </MDBContainer>
        </div>
    );
};

export default Home;