import React from 'react';

const TopPanel = () => {
    return (
        <div className="smoke-background">
            <img src="https://images.unsplash.com/photo-1563330232-57114bb0823c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80" alt="Smoke background" className="background" />
            <img src="/OffensivePluginsCentreLogo.png" alt="Offensive Plugins Logo" className="logo" />
            <h1 style={{ display: 'none' }}>OFFENSIVE PLUGINS</h1>
        </div>
    );
};



export default TopPanel;