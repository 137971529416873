import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBTypography, MDBBox } from 'mdbreact';

const Support = () => {
    return (
        <MDBContainer className="support-form">
            <div className="text-center">
                <h1>Support</h1>
            </div>
            <hr className="my-5" />
            <MDBTypography blockquote bqColor='primary'>
                <MDBBox tag='p' mb={0} className='bq-title'>
                    Installation Issues
                </MDBBox>
                <p>
                    If you’re having problems installing our plugins, for example your DAW won’t recognise them, then follow these steps:
                </p>
                <hr className="mb-2" />
                <p>
                    <li>Make sure you’re using the right plugin type for your DAW - check whether your DAW accepts AU or VST3.</li>
                    <li>Make sure your plugin file is in the right location for <a href="https://support.apple.com/en-gb/HT201532">MacOS</a> or <a href="https://helpcenter.steinberg.de/hc/en-us/articles/115000177084-VST-plug-in-locations-on-Windows">Windows</a>.</li>
                    <li>Restart your DAW - Close all DAW projects and make sure you quit the application before reopening.</li>
                    <li>Check categories - some DAWs might categorise our plugins under ‘Other’.</li>
                    <li>Restart your system - Audio plugin caches are often refreshed after startup.</li>
                    <li>Trigger a plugin re-scan via your DAW.</li>
                </p>
            </MDBTypography>
            <hr className="my-5" />
            <div className="text-center">
                Still having issues?
                <br />
                <br />
                <a href="mailto:support@offensiveplugins.com">CONTACT US</a>
            </div>
        </MDBContainer>
    );
};

export default Support;
