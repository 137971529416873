import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import Loader from 'react-loader-spinner';
import { withRouter } from "react-router-dom";
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';

import { Client } from "../prismic-configuration";

import ProductCard from '../components/ProductCard';

const Products = ({ history }) => {
    const [products, setProducts] = useState();

    useEffect(() => {
        /*
        getProducts().then((products_res) => {
            setProducts(products_res.items);
        }).catch((err) => {
            console.error(err);
        });
        */

        Client.query(
            Prismic.Predicates.at('document.type', 'product')
        ).then((products) => {
            setProducts(products.results);

        }).catch((err) => {
            console.error(err);
        });

    }, []);

    return (
        <div className="products">
            <MDBContainer className="text-center">
                <h1>Products</h1>
                <h5 className="grey-text">Browse our range of innovative audio plugins to enhance your mixes with power and control.</h5>
            </MDBContainer>
            <hr className="my-5" />
            {products == null ?
                <div className="load-center">
                    <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div>
                :
                <MDBRow>
                    {
                        products.map((product) => {
                            return <MDBCol sm="12" lg="6" key={product.id}>
                                <ProductCard
                                    title={product.data.title}
                                    subtitle={product.data.shortdescription}
                                    tag={product.data.range}
                                    bgPhoto={product.data.productdisplay.url}
                                    cta="View Plugin"
                                    price={product.data.price}
                                    tagBg={product.data.themecolor}
                                    borderBottomColor={product.data.themecolor}
                                    onClick={() => { history.push("/products/" + product.data.productname) }}
                                />
                            </MDBCol>
                        })
                    }
                </MDBRow>
            }
        </div>
    );
};

export default withRouter(Products);
