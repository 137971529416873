import React, { useState, useEffect } from "react";
import Loader from 'react-loader-spinner';
import { useParams, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBBtn, MDBRow, MDBCol } from
    "mdbreact";

import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';

import { Client } from "../prismic-configuration";

const Product = ({ history }) => {

    let { id: productName } = useParams();

    const [product, setProduct] = useState(null);

    const initiatePurchase = () => {

        let purchase = {
            'products': [
                {
                    'path': product.data.productpath,
                    'quantity': 1
                }
            ],
            'checkout': true
        }

        window.fastspring.builder.push(purchase);
    };


    useEffect(() => {

        Client.query(
            Prismic.Predicates.any('my.product.productname', [productName])
        ).then((productRes) => {
            if (productRes.results.length === 0) {
                history.push("/products");
                toast.error("The product " + productName + " does not exist!");
            }
            else {
                setProduct(productRes.results[0]);
            }
        }).catch((err) => {
            history.push("/products");
            toast.error("An error occured while trying to load this product!");
        });


    }, [])

    return (
        product == null ?
            <div className="load-center">
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
            </div>
            :
            <MDBContainer className="my-5">
                <MDBRow>
                    <MDBCol>
                        <h1>{product.data.title}</h1>
                        <span className="mr-4"><em>{product.data.shortdescription}</em></span>
                    </MDBCol>
                    <MDBCol md="4">
                        <span className="mr-4"><strong>{product.data.price === 0 ? "FREE" : "£" + product.data.price}</strong></span>
                        <MDBBtn color="primary" onClick={initiatePurchase}>PURCHASE</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <hr className="my-5" />
                <MDBRow className="d-flex align-items-center">
                    <MDBCol md="6">
                        <img src={product.data.productdisplay.url} alt={product.data.productdisplay.alt} className="img-fluid rounded z-depth-2" />
                    </MDBCol>
                    <MDBCol md="6" className="text-center my-5">
                        {RichText.render(product.data.description)}
                    </MDBCol>
                </MDBRow>
                <hr className="my-5" />
                {RichText.render(product.data.content)}
                <hr className="my-5" />
                <MDBCarousel
                    activeItem={1}
                    length={product.data.images.length}
                    showControls={true}
                    showIndicators={true}
                    className="z-depth-1"
                >
                    <MDBCarouselInner>
                        {
                            product.data.images.map((image, index) => {
                                console.log(image);
                                return <MDBCarouselItem itemId={index + 1} key={index}>
                                    <MDBView>
                                        <img
                                            className="d-block w-100"
                                            src={image.image.url}
                                            alt={product.data.title}
                                        />
                                    </MDBView>
                                </MDBCarouselItem>
                            })}
                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBContainer>
    );
};

export default withRouter(Product);