import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import Team from '../components/Team';

const About = () => {
    return (
        <MDBContainer className="my-5">
            <h1 className="text-center">About Us</h1>
            <hr className="my-5" />
            <section className="my-5">
                <h2 className="h1-responsive font-weight-bold my-5">
                    Introduction
                </h2>
                <p className="lead">
                    At Offensive Plugins we focus on producing high-quality software for every member of the audio community. Many plugins in the industry are aimed solely at professionals, with a lack of support for students and the Music Technology programmes they take. We aim to provide essential tools for those starting up, as well as robust and reliable plugins which are invaluable for experienced engineers.
                </p>
                <p className="lead">
                    Our ‘Abstract’ range of plugins brings more excitement to the mixing process, offering awesome new methods to manipulate your tracks in weird and wacky ways. Their simple graphical user interfaces make them great as plug-and-play processors, yet under the hood these plugins feature complex and powerful digital signal processing.
                </p>
                <p className="lead mb-5">
                    The ‘Platinum’ plugin line offers full functionality for those engineers needing accurate tools in their projects. These innovative processors allow you to have confidence in the decisions you make, and ultimately in the mixes you release. Our Platinum range provides you with power and control to ensure your work is your best.
                </p>
            </section>
            <Team />
        </MDBContainer>
    );
};

export default About;