import React from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBCardBody } from 'mdbreact';

const Team = () => {
    return (
        <React.Fragment>
            <h2 className="h1-responsive font-weight-bold mb-5">
                Our Team
            </h2>
            <p className="lead mb-5">
                Offensive Plugins is lead by a team of university students from the University of Surrey. They come from the prestigious Tonmeister course, which is renowned for its connections with the music industry - featuring lecturers who have worked for companies such as the BBC and Abbey Road.
            </p>
            <MDBRow>
                <MDBCol md="4" sm="12" className="d-flex align-items-center">
                    <img
                        src="https://i1.sndcdn.com/avatars-000287752515-sh2mos-t500x500.jpg"
                        className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                        tag="img"
                        alt="Leon P-S"
                    />
                </MDBCol>
                <MDBCol md="8" sm="12">
                    <h4 className="font-weight-bold mb-3">Leon P-S</h4>
                    <h6 className="font-weight-bold grey-text mb-3">
                        DIRECTOR
                    </h6>
                    <a href="https://www.linkedin.com/in/leon-p-s-29691a17a/" className="p-2 fa-lg fb-ic">
                        <MDBIcon fab icon="linkedin" />
                    </a>
                </MDBCol>
            </MDBRow>
            <hr className="my-5" />
            <MDBRow>
                <MDBCol md="4" sm="12" className="d-flex align-items-center">
                    <img
                        src="/will-avatar.png"
                        className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                        tag="img"
                        alt="Will Cassidy"
                    />
                </MDBCol>
                <MDBCol md="8" sm="12">
                    <h4 className="font-weight-bold mb-3">Will Cassidy</h4>
                    <h6 className="font-weight-bold grey-text mb-3">
                        DIRECTOR
                    </h6>
                    <a href="https://www.linkedin.com/in/will-cassidy-589163165/" className="p-2 fa-lg fb-ic">
                        <MDBIcon fab icon="linkedin" />
                    </a>
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    );
};

export default Team;