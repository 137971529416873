import React from 'react';
import PropTypes from 'prop-types';

import { MDBRow, MDBCol, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";

export const NewsCardLeft = (props) => {
    return (
        <React.Fragment>
            <MDBRow className="d-flex align-items-center">
                <MDBCol lg="5">
                    <a href={props.destination}>
                        <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                            <img
                                className="img-fluid"
                                src={props.background}
                                alt=""
                            />
                            <MDBMask overlay="white-slight" />
                        </MDBView>
                    </a>
                </MDBCol>
                <MDBCol lg="7">
                    <a href={props.destination} className="primary-text">
                        <h6 className="font-weight-bold mb-3">
                            <MDBIcon icon={props.icon} className="pr-2" />
                            {props.tag}
                        </h6>
                    </a>
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>{props.title}</strong>
                    </h3>
                    <h5>
                        {props.description}
                    </h5>
                    <p>
                        {props.date}
                    </p>
                    <MDBBtn color="primary" size="md" className="waves-light" href={props.destination}>
                        {props.actionTitle}
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="my-5" />
        </React.Fragment>
    );
};

export const NewsCardRight = (props) => {
    return (
        <React.Fragment>
            <MDBRow>
                <MDBCol lg="7">
                    <a href={props.destination} className="orange-text">
                        <h6 className="font-weight-bold mb-3">
                            <MDBIcon icon={props.icon} className="pr-2" />
                            {props.tag}
                        </h6>
                    </a>
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>{props.title}</strong>
                    </h3>
                    <h5>
                        {props.description}
                    </h5>
                    <p>
                        {props.date}
                    </p>
                    <MDBBtn
                        color="orange"
                        size="md"
                        className="mb-lg-0 mb-4 waves-light"
                        href={props.destination}
                    >
                        {props.actionTitle}
                    </MDBBtn>
                </MDBCol>
                <MDBCol lg="5" className="d-flex align-items-center">
                    <a href={props.destination}>
                        <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                            <img
                                className="img-fluid"
                                src={props.background}
                                alt=""
                            />
                            <MDBMask overlay="white-slight" />
                        </MDBView>
                    </a>
                </MDBCol>
            </MDBRow>
            <hr className="my-5" />
        </React.Fragment>
    );
};

const propTypes = {
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired, // Refers to an MDBIcon type
    background: PropTypes.string.isRequired,
    actionTitle: PropTypes.string.isRequired
};

NewsCardLeft.propTypes = propTypes;
NewsCardRight.propTypes = propTypes;

